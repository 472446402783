import React, { useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import Link from "../components/Link"

import "../styles/tour.scss"

export default function Tour({ location: gatsbyLocation, pageContext }) {
  const tours = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("tours")).filter(
            tour => tour?.lng === pageContext.language
          )
        : [],
    []
  )
  const tour = useMemo(
    () => gatsbyLocation?.state?.tour || tours[0],
    [gatsbyLocation?.state?.tour]
  )
  const location = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("locations")).find(
            loc => tour?.location === loc.location
          )
        : [],
    [tour?.location]
  )

  const [activeDate, setActiveDate] = useState(tour?.dates[0])
  const [activeImg, setActiveImg] = useState(tour?.imgs[0]?.url)

  const getTourBoxes = useMemo(
    () =>
      activeDate?.time ? (
        <div className="dates-container">
          {tour?.dates.map(date => (
            <div
              key={date.time}
              onClick={() => setActiveDate(date)}
              className={`date-cube ${
                date.time === activeDate?.time ? "active" : ""
              }`}
            >
              {new Date(date.time).toLocaleDateString("en-GB")}
            </div>
          ))}
        </div>
      ) : null,
    [activeDate?.time]
  )

  const getDateInfo = useMemo(() => {
    const minutes = new Date(activeDate?.time).getMinutes()
    return activeDate?.time ? (
      <>
        <p>
          <b>
            <FormattedMessage id="tour_tour-begins" />:
          </b>{" "}
          {new Date(activeDate.time).getHours()}:
          {minutes < 10 ? `0${minutes}` : minutes}
        </p>
        <p>
          <b>
            <FormattedMessage id="tour_cost" />:
          </b>{" "}
          {activeDate.cost}
        </p>
        <p>
          <b>
            <FormattedMessage id="tour_registration" />:
          </b>{" "}
          {activeDate.register}
        </p>
        <br />
        <br />
        <Link
          href={"/register"}
          state={{ event: { ...activeDate, ...tour } }}
          className="tour-cta"
        >
          <FormattedMessage id="tour_register-now" />
        </Link>
      </>
    ) : null
  }, [activeDate?.time, activeDate?.cost, activeDate?.register])

  const getImgsRow = useMemo(
    () =>
      tour?.imgs.length > 1 ? (
        <div className="imgs-row">
          {tour?.imgs.map((img, i) => (
            <img
              onClick={() => setActiveImg(img.url)}
              key={img.url}
              src={img.url}
              alt={`tour ${i}`}
              className={`${img.url === activeImg ? "active" : ""}`}
            />
          ))}
        </div>
      ) : null,
    [tour?.imgs, activeImg]
  )

  return (
    <Layout pageContext={pageContext}>
      <div
        className="page-banner"
        style={{ backgroundImage: `url(${location?.banner})` }}
      >
        <div className={`page-banner-content ${pageContext.language}`}>
          <h1>{location?.location}</h1>
          <h2>{location?.subtitle}</h2>
        </div>
      </div>
      <div className="tour-page">
        <div className="tour-details">
          <h3>{tour?.title}</h3>
          <h4>{tour?.subtitle}</h4>
          {getTourBoxes}
          <p className="content">{tour?.content}</p>
          {getDateInfo}
        </div>
        <div className="imgs-container">
          <img className="active-img" src={activeImg} alt="tour-active-img" />
          {getImgsRow}
        </div>
      </div>
    </Layout>
  )
}

export { Head } from "../components/Head"
